import(/* webpackMode: "eager" */ "/home/runner/work/www.nanolyrics.com/www.nanolyrics.com/node_modules/.pnpm/next@14.3.0-canary.11_@babel+core@7.24.7_react-dom@18.3.1_react@18.3.1/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/www.nanolyrics.com/www.nanolyrics.com/node_modules/.pnpm/next@14.3.0-canary.11_@babel+core@7.24.7_react-dom@18.3.1_react@18.3.1/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/www.nanolyrics.com/www.nanolyrics.com/node_modules/.pnpm/next@14.3.0-canary.11_@babel+core@7.24.7_react-dom@18.3.1_react@18.3.1/node_modules/next/font/google/target.css?{\"path\":\"src/app/[lang]/(website)/layout.tsx\",\"import\":\"Roboto\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":[\"100\",\"300\",\"400\",\"500\",\"700\",\"900\"]}],\"variableName\":\"roboto\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/www.nanolyrics.com/www.nanolyrics.com/src/ui/molecules/Header/Header.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/www.nanolyrics.com/www.nanolyrics.com/src/ui/molecules/LeftNav/LeftNav.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/www.nanolyrics.com/www.nanolyrics.com/src/ui/organisms/footer/Footer.tsx");
